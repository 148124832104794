import React, { useEffect, useState } from "react";
import { Button } from "../button";
import { TextField } from "../text-field/components/TextField";
import logo from "../../pictures/logo.png";
import "./style.css";
import { useLocation, useNavigate } from "react-router-dom";
import request from "superagent";
import Swal from "sweetalert2";
import { handleOfflineResponse, Toast } from "../errorNotifier";
import { loader } from "../LoadingSpinner";
import { MDBIcon } from "mdb-react-ui-kit";
import withReactContent from "sweetalert2-react-content";

export const FormPurchase = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [init, setInit] = useState(false);
  const [email, setEmail] = useState("");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleFetchData = async () => {
    const data = {
      ChildId: id,
    };

    await request
      .post("https://api.academy.foudhan.com/user_reserved_accounts.php")
      .type("application/json")
      .send(data)
      .then((response) => {
        setAccounts(response.body);
      })
      .catch((err) => {
        console.log("Error message:", err.response);
        console.log("ERROR", err);
      });
  };

  const handleResearveAccount = async () => {
    const data = {
      GuardianEmail: email,
      ChildId: id,
      ChildName: name,
    };

    loader({ title: "Processing", text: "please wait..." });

    await request
      .post("https://api.academy.foudhan.com/generate_payment_account.php")
      .type("application/json")
      .send(data)
      .then((response) => {
        setAccounts(response.body.accounts);

        Toast.fire({
          icon: "success",
          title:
            "Accounts generated successfully, you can proceed with your payments through any of the account.",
        });
      })
      .catch((err) => {
        console.log("ERROR", err);
        Toast.fire({
          icon: "error",
          title: handleOfflineResponse(err),
        });
      });
  };

  useEffect(() => {
    if (location.state && location.state.userEmail) {
      if (!init) {
        setEmail(location.state.userEmail);
        setId(location.state.userId);
        setName(location.state.userName);
        handleFetchData();
      }
    } else {
      navigate("/login");
    }
  }, [email]);

  const stringFormatter = (e) => {
    const inputValue = e;
    // Convert to lowercase and capitalize each word
    const formattedValue = inputValue
      .toLowerCase()
      .replace(/(^|\s)\S/g, (match) => match.toUpperCase());

    return formattedValue;
  };

  return (
    <div className="accounts">
      <div className="container">
        <img
          onClick={() => {
            navigate("/");
          }}
          className="LOGO-MCCHST mb-4"
          alt="Logo MCCHST"
          src={logo}
        />

        <h3 style={{ color: "white" }}>FORM PURCHASE</h3>

        <p style={{ color: "white", paddingTop: 20 }} className="">
          Please note that you are about to pay for the application form fee of
          <span className="mx-2" style={{ fontWeight: "bold" }}>
            ₦2000
          </span>
          which is
          <span className="mx-2" style={{ fontWeight: "bold" }}>
            NON-REFUNDABLE.
          </span>
          If you agree to make the payment, please proceed by clicking on the
          <strong> Generate Deposit Account</strong> button below.
        </p>

        {accounts.length === 0 ? (
          <div className="mt-4">
            <div onClick={handleResearveAccount} className="cus-button">
              Generate Deposit Account
              <MDBIcon
                size="lg"
                className="me-2 m-2"
                fab
                icon="cc-amazon-pay"
              />
            </div>
          </div>
        ) : (
          <ul>
            <br />
            <div>
              <strong>PAYMENT ACCOUNTS</strong>
            </div>
            <hr />

            {accounts.map((account_data) => {
              return (
                <div>
                  <div>
                    <strong>{account_data.bankName}</strong>
                  </div>
                  <div>Account Name: {account_data.accountName} </div>
                  <div>
                    Account Number:
                    <strong> {account_data.accountNumber}</strong>
                  </div>
                  <br />
                  <hr />
                </div>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};
