import React, { useEffect, useState } from "react";
import { Button } from "../button";
import { TextField } from "../text-field/components/TextField";
import logo from "../../pictures/logo.png";
import "./style.css";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import request from "superagent";
import Swal from "sweetalert2";
import { Toast } from "../errorNotifier";
import { loader } from "../LoadingSpinner";
import { MDBIcon } from "mdb-react-ui-kit";
import withReactContent from "sweetalert2-react-content";

export const AccountsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);

  const handleFetchData = async () => {
    const data = {
      GuardianEmail: email,
    };

    await request
      .post("https://api.academy.foudhan.com/get_junior_accounts.php")
      .type("application/json")
      .send(data)
      .then((response) => {
        setAccounts(response.body);
        setIsProcessing(false);
      })
      .catch((err) => {
        console.log("Error message:", err.response);
        console.log("ERROR", err);
        setIsProcessing(false);
      });
  };

  useEffect(() => {
    if (location.state && location.state.userEmail) {
      setEmail(location.state.userEmail);
      handleFetchData();
    } else {
      navigate("/login");
    }
  }, [email]);

  const stringFormatter = (e) => {
    const inputValue = e;
    // Convert to lowercase and capitalize each word
    const formattedValue = inputValue
      .toLowerCase()
      .replace(/(^|\s)\S/g, (match) => match.toUpperCase());

    return formattedValue;
  };

  return (
    <div className="accounts">
      <div className="container">
        <img
          onClick={() => {
            navigate("/");
          }}
          className="LOGO-MCCHST mb-4"
          alt="Logo MCCHST"
          src={logo}
        />

        <div className="mt-4">
          <div
            onClick={() => {
              if (isProcessing) {
                Toast.fire({
                  icon: "info",
                  title: "please wait for the data to finisg loading",
                });
                return;
              }

              withReactContent(Swal).fire({
                title: "User Account",
                html: "Please enter the fullname of the applicant.",
                input: "text",
                preConfirm: async () => {
                  const suppliedName = Swal.getInput().value;

                  if (!suppliedName) {
                    Toast.fire({
                      icon: "error",
                      title: "name must be provided",
                    });

                    return;
                  }

                  loader({ title: "Processing", text: "please wait..." });

                  const data = {
                    email: email,
                    name: stringFormatter(suppliedName),
                  };

                  await request
                    .post(
                      "https://api.academy.foudhan.com/create_junior_account.php"
                    )
                    .type("application/json")
                    .send(data)
                    .then((response) => {
                      const message = response.body.message;

                      Swal.fire({
                        title: "Success",
                        text: message,
                        icon: "success",
                      });

                      // Re-fetch the data to show the newly added record
                      handleFetchData();
                    })
                    .catch((err) => {
                      let errorText = "";

                      if (err.response) {
                        errorText = err.response.text;
                      } else {
                        errorText = "Network error, please check and try again";
                      }

                      Toast.fire({
                        icon: "error",
                        title: errorText,
                      });
                    });
                },
              });
            }}
            className="cus-button"
          >
            Create Account
            <MDBIcon size="lg" className="me-2 m-2" fas icon="pen-to-square" />
          </div>
        </div>

        <p style={{ color: "white" }} className="">
          Please, create accounts for the number of <b>Applicants</b> you are
          enrolling. We consider you to be their guardian through out their stay
          in the Academy.
        </p>

        {accounts.map((account, i) => {
          return (
            <AccountsComponent
              name={account.Name}
              id={account.ApplicationId}
              email={email}
              key={i}
            />
          );
        })}
      </div>
    </div>
  );
};

const AccountsComponent = (props) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        navigate("/form-purchase", {
          state: {
            userEmail: props.email,
            userId: props.id,
            userName: props.name,
          },
        });
      }}
      className="user-card "
    >
      <div
        style={{
          fontSize: "12px",
          textAlign: "right",
          width: "100%",
          fontWeight: "bold",
          cursor: "pointer",
        }}
      >
        ID: {props.id}
      </div>
      <div style={{ fontWeight: "bold" }}>{props.name}</div>
    </div>
  );
};
