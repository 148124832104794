import React, { useEffect, useState } from "react";

const HtmlTemplateRenderer = () => {
  const [htmlContent, setHtmlContent] = useState("");

  useEffect(() => {
    fetch("/html/index.html")
      .then((response) => response.text())
      .then((data) => setHtmlContent(data))
      .catch((error) => console.error("Error fetching HTML template:", error));
  }, []);

  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

export default HtmlTemplateRenderer;
