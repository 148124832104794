import * as React from "react";
import Paper from "@mui/material/Paper";
import "../admin/css/style.css";
import {
  MDBBtn,
  MDBCardBody,
  MDBCardText,
  MDBCol,
  MDBIcon,
  MDBRow,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import request from "superagent";
import Swal from "sweetalert2";
import { Toast } from "../errorNotifier";
import { loader } from "../LoadingSpinner";
import { MuiFileInput } from "mui-file-input";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { TextField } from "@mui/material";
import editIcon from "../../pictures/edit.png";
import { useNavigate } from "react-router-dom";

export default function AdmissionTab() {
  const navigate = useNavigate();
  const [init, setInit] = useState(false);
  const [admissionSms, setAdmissionSms] = useState("");
  const [admissionEmail, setAdmissionEmail] = useState("");
  const [listOfAdmittedStudents, setListOfAdmittedStudents] = useState([]);

  useEffect(() => {
    if (!init) {
      handleFetchData();
      setInit(true);
    }
  }, [init]);

  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    // setFile(e.target.files[0]);
    setFile(e);
  };

  const handleSubmit = async (e) => {
    if (!file) {
      Swal.fire({
        title: "Error!",
        text: "No file selected",
        icon: "error",
      });
      return;
    }

    loader({
      title: "Uploading",
      text: "Please wait...",
    });

    const formData = new FormData();
    formData.append("csv", file); // Ensure the name 'csv' matches your PHP script

    try {
      const res = await request
        .post("https://api.academy.foudhan.com/admin/admission_upload.php")
        .send(formData)
        .set("Accept", "application/json");

      Toast.fire({
        icon: "success",
        title: "Uploaded successfully",
      });
    } catch (err) {
      console.error("Error uploading file", err);
      Swal.fire({
        title: "Error!",
        text: "There was an error uploading the file.",
        icon: "error",
      });
    }
  };

  const handleSaveNotification = async () => {
    if (navigator.onLine) {
      // progress spinner
      loader({
        title: "Updating",
        text: "Please! wait.",
      });

      const data = {
        SmsNotification: admissionSms,
        EmailNotification: admissionEmail,
      };

      await request
        .post("https://api.academy.foudhan.com/admin/save_admission_notifs.php")
        .type("application/json")
        .send(data)
        .then((response) => {
          const message = response.text;
          console.log(message);
          Toast.fire({
            icon: "success",
            title: "updated successfully",
          });
        })
        .catch((err) => {
          let errorText = err.response.text;
          console.log(errorText);

          Swal.fire({
            title: "Error!",
            text: "There is occoured an error",
            icon: "error",
          });
        });
    } else {
      Toast.fire({
        icon: "error",
        title: "No internet connection",
      });
    }
  };

  const handleFetchData = async () => {
    await request
      .get("https://api.academy.foudhan.com/admin/get_admitted.php")
      .type("application/json")
      .then((response) => {
        setListOfAdmittedStudents(response.body.list);
        setAdmissionSms(response.body.smsNote);
        setAdmissionEmail(response.body.emailNote);
      })
      .catch((err) => {
        // let errorText = err.response.text;
        console.log(err);
      });
  };

  // Function to handle the icon click
  const handleStudentProfileLink = (email) => {
    navigate("/student-profile", {
      state: { userEmail: email },
    });
  };

  return (
    <div className="m-4 d-flex flex-column align-items-center">
      <MDBCardBody>
        <MDBCardText>
          <h4>Admission Management</h4>
        </MDBCardText>
      </MDBCardBody>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <MDBRow style={{ padding: "10px" }}>
          <div style={{ fontWeight: "900", padding: "20px" }}>
            Admission upload
          </div>
          <MDBCol style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <MuiFileInput
                InputProps={{
                  inputProps: {
                    accept: ".csv",
                  },
                  startAdornment: <AttachFileIcon />,
                }}
                value={file}
                placeholder="choose the file here..."
                onChange={handleFileChange}
                clearIconButtonProps={{
                  title: "Remove",
                  children: <CloseIcon fontSize="small" />,
                }}
              />

              <MDBBtn
                onClick={handleSubmit}
                style={{ background: "#5f0404", height: "56px" }}
                className="p-3"
              >
                Upload
              </MDBBtn>
            </div>
          </MDBCol>
        </MDBRow>
      </Paper>

      <Paper className="mt-2" sx={{ width: "100%", overflow: "hidden" }}>
        <MDBRow style={{ padding: "10px" }}>
          <div style={{ fontWeight: "900", padding: "20px" }}>
            Notification of Admission
          </div>

          <MDBCol style={{ display: "flex", justifyContent: "center" }}>
            <TextField
              value={admissionSms}
              className="center-cmp w-100"
              variant="outlined"
              multiline
              onChange={(e) => {
                setAdmissionSms(e.target.value);
              }}
              label="SMS Notification"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </MDBCol>

          <MDBCol style={{ display: "flex", justifyContent: "center" }}>
            <div></div>
            <TextField
              value={admissionEmail}
              className="center-cmp w-100"
              variant="outlined"
              multiline
              onChange={(e) => {
                setAdmissionEmail(e.target.value);
              }}
              label="Email Notification"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <div
              onClick={handleSaveNotification}
              className="cus-button w-25 my-2"
            >
              Update
            </div>
          </MDBCol>
        </MDBRow>
      </Paper>

      <Paper className="mt-2" sx={{ width: "100%", overflow: "hidden" }}>
        <MDBRow style={{ padding: "10px" }}>
          <div style={{ fontWeight: "900", padding: "20px" }}>
            Admitted students
          </div>

          <MDBCol style={{ display: "flex", justifyContent: "center" }}>
            <table>
              <tr>
                <th>SN</th>
                <th>App No.</th>
                <th>Fullname</th>
                <th>Department</th>
                <th>Programme</th>
                <th>Mode of Entry</th>
                <th>View</th>
              </tr>
              {listOfAdmittedStudents.map((std, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{std.ApplicationNo}</td>
                    <td>{std.Fullname}</td>
                    <td>{std.Department}</td>
                    <td>{std.Programme}</td>
                    <td>{std.ModeOfEntry}</td>
                    <td>
                      <MDBIcon
                        size="lg"
                        fas
                        icon="share"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => handleStudentProfileLink(std.Email)}
                      />
                    </td>
                  </tr>
                );
              })}
            </table>
          </MDBCol>
        </MDBRow>
      </Paper>
    </div>
  );
}
