import { Alert, Snackbar } from "@mui/material";
import Swal from "sweetalert2";

export const ErrorNotifier = (props) => {
  return (
    <div>
      <Snackbar
        open={props.show}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        autoHideDuration={6000}
        onClose={props.onErrClose}
      >
        <Alert style={{ textAlign: "center" }} severity="error" sx={4}>
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  },
});

export const handleOfflineResponse = (error) => {
  const errorMessage = error;

  console.log(error);

  // Define the regex pattern to match the error message
  const networkErrorPattern =
    /Request has been terminated.*(network is offline|Access-Control-Allow-Origin|the page is being unloaded)/i;

  // Check if the error message matches the network error pattern
  if (networkErrorPattern.test(error)) {
    // If it matches, return "No network" to the user
    return "Seems like you have a poor network";
  } else {
    // Handle other errors as needed
    return errorMessage;
  }
};
