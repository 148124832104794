import { MDBCard } from "mdb-react-ui-kit";
import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { useNavigate } from "react-router-dom";

export default function CarouselComp() {
  // const [init, setInit] = useState(false);
  const refPc = useRef(null);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!init) {
  //     refPc.current.scrollIntoView({
  //       behavior: "smooth",
  //       block: "start",
  //     });
  //     setInit(true);
  //   }
  // });
  return (
    <div ref={refPc} style={{ width: "100%", background: "maroon" }}>
      <Carousel autoPlay infiniteLoop interval={4000} showArrows={true}>
        <CarouselItem
          title="Exciting News!"
          description="Enrollment Now Open for Nursery 1, Primary 1, and JSS 1!
Prepare your child for a future of success in our cutting-edge, digitally advanced academy. At our school, we don’t just teach; we inspire young minds to explore, create, and excel in a tech-driven world. With a curriculum that blends traditional learning with the latest digital tools, your child will gain the skills needed to thrive in the 21st century.

Why choose us?

Innovative Learning Environment: Our classrooms are equipped with the latest technology to enhance learning.
Experienced Educators: Our teachers are not just instructors; they are mentors dedicated to nurturing your child's potential.
Holistic Development: We focus on both academic excellence and personal growth, ensuring your child is well-rounded and confident.
Don’t miss out on this opportunity to provide your child with the best start in their educational journey. Secure their spot today and be part of a community that values innovation, creativity, and excellence!"
        />

        <CarouselItem
          title="Empower Yourself"
          description="With our certificate programmes, "
        />
      </Carousel>
    </div>
  );
}

const CarouselItem = ({ title, description }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "maroon",
      height: "100%",
      width: "100%",
      color: "white",
    }}
  >
    <div>
      <div
        style={{
          alignSelf: "center",
          // color: "transparent",
          fontFamily: "Arbutus-Regular Helvetica",
          fontSize: "3vw",
          fontWeight: 800,
          letterSpacing: 0,
          lineHeight: "normal",
          marginTop: "10px",
          position: "relative",
          textSlign: "center",
          textShadow: "0px 24px 22.1px #0000008c",
          width: "100%",
        }}
      >
        {title}
      </div>
      <p style={{ padding: "10px" }}>{description}</p>
    </div>
  </div>
);
