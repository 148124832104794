import React from "react";
import { Button } from "../../button";
import { MenuButton } from "../../menu-button";
import stdPic from "../../../pictures/std_pic2.jpg";
import facebookLogo from "../../../pictures/🦆 icon _facebook squared_.png";
import twitterLogo from "../../../pictures/🦆 icon _twitter_.png";
import telegramLogo from "../../../pictures/🦆 icon _telegram_.png";
import adminImg from "../../../pictures/admin_img.png";
import "./style.css";
import { useNavigate } from "react-router-dom";
import CarouselComp from "../../careusal";

export const HomeDesktop = () => {
  const navigate = useNavigate();
  const programmesData = [
    "Three month certificate in Graphics and Animation with Blender",
    "One week certificate in Prompt Engeenering",
    "Six month certificate in Robotics and Embaded device programming",
    "Three month certificate in AI asisted Arabic Learning",
  ];

  return (
    <div className="desktop">
      <div className="head-section">
        <div className="menu-bar">
          <MenuButton label="Apply Now" navigateTo={"apply"} />
          <MenuButton label="Login" navigateTo={"login"} />
          <MenuButton label="Our History" navigateTo={"about"} />
          <MenuButton label="Support" navigateTo={"contact"} />
        </div>

        <div className="frame-2">
          <p className="p">
            Foudhan Academy fosters an inclusive environment where students
            thrive using digital tools. We personalize education to meet each
            student's unique needs, preparing them for success in a rapidly
            evolving world. Our dedicated educators nurture academic excellence,
            creativity, critical thinking, and a lifelong passion for learning.
          </p>
          <Button
            handleClick={() => {
              navigate("/apply");
            }}
            className="button-instance"
            label="APPLY NOW"
          />
        </div>
        <p className="empowering-futures">
          <span className="span"></span>
          <span className="text-wrapper-4">
            Academic Excellence with Modern Skills
          </span>
          <span className="span">
            <br />
          </span>
          <span className="text-wrapper-5">
            <br />
            Unleash Your Potential, Inspire Change!
          </span>
        </p>

        <div className="logo-frame">
          <div className="colleges-title">
            <div className="college-caption">
              <span className="sub-caption-color">FOUDHAN STARNDARD</span>
              <br />
              INTERNATIONAL ACADEMY LTD. FUNTUA
            </div>
          </div>
          <div className="std-image-container">
            <img className="LOGO-MCCHST" alt="student" src={stdPic} />
          </div>
        </div>
      </div>

      <div className="news-section">
        <div className="carousel-section">
          <div className="captions">
            Enrollment Now Open for Nursery 1, Primary 1, and JSS 1!
          </div>
          <p
            style={{
              textAlign: "left",
              padding: "10px",
              marginLeft: "50px",
              marginRight: "50px",
            }}
          >
            Prepare your child for a future of success in our cutting-edge,
            digitally advanced academy. At our school, we don’t just teach; we
            inspire young minds to explore, create, and excel in a tech-driven
            world. With a curriculum that blends traditional learning with the
            latest digital tools, your child will gain the skills needed to
            thrive in the 21st century.
          </p>
          <div style={{ marginLeft: "50px" }}>
            <div
              onClick={() => {
                navigate("/apply");
              }}
              className="get-started-button"
            >
              Get started here
            </div>
          </div>

          <div className="captions">Why are we different?</div>
          <ul
            style={{
              textAlign: "left",
              padding: "10px",
              marginLeft: "50px",
              marginRight: "50px",
            }}
          >
            <li>
              <strong>Innovative Learning Environment:</strong> Our classrooms
              are equipped with the latest technology to enhance learning.
            </li>
            <li>
              <strong>Experienced Educators:</strong> Our teachers are not just
              instructors; they are mentors dedicated to nurturing your child's
              potential.
            </li>
            <li>
              <strong>Holistic Development:</strong> We focus on both academic
              excellence and personal growth, ensuring your child is
              well-rounded and confident.
            </li>
          </ul>

          <p
            style={{
              textAlign: "left",
              padding: "10px",
              marginLeft: "50px",
              marginRight: "50px",
            }}
          >
            Don’t miss out on this opportunity to provide your child with the
            best start in their educational journey.
            <strong>
              Secure their spot today and be part of a community that values
              innovation, creativity, and excellence!
            </strong>
          </p>
        </div>
      </div>

      <div className="motive-frame">
        <p className="why-choose-muslim">
          <span className="text-wrapper-15">Why Choose </span>
          <span className="text-wrapper-16">Foudhan</span>
          <span className="text-wrapper-15"> Academy?</span>
        </p>

        <div className="motive-section">
          <div className="motive-section-2">
            <div className="global-prospect">
              <p className="div-4">
                <div className="text-wrapper-17">🌍</div>

                <div className="text-wrapper-18">Global Outlook:</div>
                <div className="motive-main-text">
                  Embrace a global outlook in education. Our programs integrate
                  international standards and perspectives, equipping students
                  with the knowledge and skills to thrive in a globally
                  connected world.
                </div>
              </p>
            </div>
            <div className="integrity">
              <p className="div-4">
                <div className="text-wrapper-17">🚀</div>

                <div className="text-wrapper-18">
                  Innovation-Driven Learning:
                </div>
                <div className="motive-main-text">
                  Experience a forward-thinking education where creativity and
                  technology converge. Our academy fosters innovative thinking,
                  equipping students with the tools to solve tomorrow's
                  challenges today.
                </div>
              </p>
            </div>

            <div className="inclusive-community">
              <p className="div-4">
                <div className="text-wrapper-17">🤝</div>

                <div className="text-wrapper-18">Welcoming Community:</div>
                <div className="motive-main-text">
                  Join a vibrant and supportive community that celebrates
                  diversity and inclusion. Our academy nurtures an environment
                  where every student feels valued, respected, and empowered to
                  thrive, regardless of their background.
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="programs-section">
        <div className="flexcontainer">
          <p className="text">
            <span className="text-wrapper-12">
              Other courses on offer:
              <br />
            </span>
            <div>
              <ul>
                {programmesData.map((programme, index) => (
                  <li key={index} className="programs-text">
                    {programme}
                  </li>
                ))}
              </ul>
            </div>
          </p>
        </div>
      </div>

      <div className="welcome-section">
        <div style={{ margin: "20px" }}>
          <h1>Welcome to Our Academy!</h1>
          <p>
            We are delighted to have you explore our vibrant and innovative
            educational community. At our academy, we believe in nurturing young
            minds to become the leaders of tomorrow. Our state-of-the-art
            facilities, dedicated educators, and a curriculum that blends
            traditional values with modern technology are designed to provide an
            exceptional learning experience.
          </p>

          <p>
            As you tour our campus, we invite you to discover the unique
            programs and opportunities that set us apart. From our interactive
            classrooms to our creative extracurricular activities, every aspect
            of our academy is crafted to inspire curiosity, creativity, and a
            love for learning.
          </p>

          <h2>What to Expect During Your Visit:</h2>
          <ul>
            <li>
              <strong>Interactive Classrooms:</strong> See our students in
              action as they engage with technology-driven lessons that make
              learning fun and effective.
            </li>
            <li>
              <strong>Meet Our Educators:</strong> Get to know the passionate
              teachers who are committed to guiding your child's educational
              journey.
            </li>
            <li>
              <strong>Explore Our Facilities:</strong> Visit our well-equipped
              labs, libraries, and recreational areas that contribute to a
              holistic learning environment.
            </li>
            <li>
              <strong>Q&A Session:</strong> Have your questions answered by our
              staff and learn more about how we can support your child's growth
              and development.
            </li>
          </ul>

          <p>
            Thank you for considering our academy for your child's education. We
            look forward to showing you how we can help them thrive in a world
            that demands both knowledge and creativity. Enjoy your visit!
          </p>

          <p>
            <strong>Warm regards,</strong>
            <br />
            Academy Director.
          </p>
        </div>

        <div style={{ margin: "20px", marginBottom: "30px" }}>
          <h2>Our Vision</h2>
          <p>
            To be a leading educational institution where innovation meets
            excellence, empowering students to become creative, responsible, and
            compassionate leaders who will shape the future of our world.
          </p>

          <h2>Our Mission</h2>
          <p>
            Our mission is to provide a nurturing and dynamic learning
            environment that combines cutting-edge technology with a strong
            foundation in traditional values. We are dedicated to:
          </p>
          <ul>
            <li>
              <strong>Inspiring Curiosity:</strong> Encouraging students to
              explore, question, and discover through a curriculum that fosters
              a love for learning.
            </li>
            <li>
              <strong>Promoting Excellence:</strong> Ensuring that every student
              achieves their highest potential through personalized education
              and a commitment to academic rigor.
            </li>
            <li>
              <strong>Developing Character:</strong> Instilling values of
              integrity, empathy, and respect, preparing students to contribute
              positively to society.
            </li>
            <li>
              <strong>Embracing Innovation:</strong> Integrating the latest
              educational technologies to enhance learning experiences and
              prepare students for a rapidly changing world.
            </li>
            <li>
              <strong>Building Community:</strong> Creating a supportive and
              inclusive environment where students, teachers, and families work
              together to achieve shared goals.
            </li>
          </ul>
        </div>
      </div>

      <div className="footer-section">
        <div className="div-wrapper">
          <div className="text-wrapper-10">
            Copyright © 2024 Foudhan Academy. All Rights Reserved.
            <div className="powered-text">
              Powered by:
              <a
                href="https://foudhan.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Foudhan Technologies
              </a>
              .
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
