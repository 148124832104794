// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/database";

const firebaseConfig = {
  apiKey: "AIzaSyCl-hBoQbRFOavQ5hFEfQZzdq9l93mjSno",
  authDomain: "fauzanacademy-90947.firebaseapp.com",
  projectId: "fauzanacademy-90947",
  storageBucket: "fauzanacademy-90947.appspot.com",
  messagingSenderId: "905036247977",
  appId: "1:905036247977:web:f744ab047979150fe70ab8",
  measurementId: "G-8QPEQ2CM8W",
};

firebase.initializeApp(firebaseConfig);
const database = firebase.database(); // Get the database reference

export { firebase, database };
