import React from "react";
import logo from "../../pictures/logo.png";
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <div>
      <header
        style={{
          backgroundColor: "#5f0404",
          color: "#fff",
          textAlign: "center",
          padding: "10px",
        }}
      >
        <img
          style={{ width: "100px", height: "100px", cursor: "pointer" }}
          src={logo}
          alt="logo"
          onClick={() => {
            navigate("/");
          }}
        />
        <h1 style={{ fontSize: "3vw" }}>
          <span style={{ fontStyle: "italic", color: "#f5e559" }}>Foudhan</span>
          <br />
          Starndard International Academy Funtua
        </h1>
      </header>

      <section
        style={{
          width: "80%",
          margin: "20px auto",
          padding: "20px",
          backgroundColor: "#fff",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          textAlign: "left",
        }}
      >
        <h2>About Us</h2>

        <p>
          Founded with a bold ambition, we set out to create a community that
          exemplifies excellence, discipline, and a deep engagement with modern
          technologies. Our vision is to build a space where innovation and
          creativity aren't just encouraged—they are a part of the daily fabric
          of our lives.
        </p>

        <p>
          As a group of forward-thinking youth, we recognized the transformative
          power of education and technology in shaping the future. Driven by
          this realization, we began searching for ways to turn our dreams into
          reality. Our journey started with the establishment of a digital
          school, which serves as the cornerstone of our broader mission.
        </p>

        <p>
          This digital school is more than just a place of learning; it is a
          stepping stone toward actualizing our grander vision. It embodies our
          commitment to providing a disciplined yet dynamic environment where
          students are equipped with the skills and mindset needed to navigate
          and thrive in a rapidly evolving technological landscape.
        </p>

        <p>
          Our goal is to expand beyond the digital school, creating a
          comprehensive ecosystem where ideas flourish, innovation is the norm,
          and every individual is empowered to contribute meaningfully to
          society. Through continuous learning, collaboration, and a relentless
          pursuit of excellence, we aim to build a community that not only stays
          current with modern technologies but also leads the charge in defining
          the future.
        </p>

        <p>
          We invite you to join us on this exciting journey as we strive to make
          our vision a reality—one that will have a lasting impact on
          individuals, communities, and the world at large.
        </p>

        <h2>Vision</h2>
        <p>
          To be a leading educational institution where innovation meets
          excellence, empowering students to become creative, responsible, and
          compassionate leaders who will shape the future of our world.
        </p>

        <h2>Our Mission</h2>
        <p>
          Our mission is to provide a nurturing and dynamic learning environment
          that combines cutting-edge technology with a strong foundation in
          traditional values. We are dedicated to:
        </p>
        <ul>
          <li>
            <strong>Inspiring Curiosity:</strong> Encouraging students to
            explore, question, and discover through a curriculum that fosters a
            love for learning.
          </li>
          <li>
            <strong>Promoting Excellence:</strong> Ensuring that every student
            achieves their highest potential through personalized education and
            a commitment to academic rigor.
          </li>
          <li>
            <strong>Developing Character:</strong> Instilling values of
            integrity, empathy, and respect, preparing students to contribute
            positively to society.
          </li>
          <li>
            <strong>Embracing Innovation:</strong> Integrating the latest
            educational technologies to enhance learning experiences and prepare
            students for a rapidly changing world.
          </li>
          <li>
            <strong>Building Community:</strong> Creating a supportive and
            inclusive environment where students, teachers, and families work
            together to achieve shared goals.
          </li>
        </ul>

        <h2>Contact Us</h2>
        <p>For inquiries and more information, please contact us at:</p>
        <address>
          Email: info@foudhan.com
          <br />
          Phone: +234 7064549822, +234 8033487740, 08066386870
          <br />
          Address: Shagari Quarters near KTARDA, Bakori byepass Funtua, Katsina
          State.
        </address>
      </section>
    </div>
  );
};

export default AboutUs;
